import styled from 'styled-components'
import { FormikInputField } from '@components/Formik/FormikInputField'

export const Wrapper = styled.div`
  max-width: 420px;
`

export const CustomInputField = styled(FormikInputField)`
  padding-bottom: 0.5rem;
  input {
    background-color: #ededee;
  }
`

export const WrapperInputCheckbox = styled.div`
  span {
    background-color: #ededee;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
  }
`

export const FormInputGroup = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  gap: 1rem;
  margin-top: 1rem;
`

export const InputWrapper = styled.div`
  position: relative;

  input {
    background-color: rgb(237, 237, 238);
  }

  p {
    margin: 0;
    position: absolute;
    bottom: -20px;
  }
`
