import styled, { css } from 'styled-components'
import { InputFieldAttrProps } from '@components/Form/InputField/index'
import { InputFeedbackStatus } from '@components/Basic/InputFeedback'

export interface InputSlotProps {
  $slot?: 'left' | 'right'
}
export interface InputStyledProps {
  $direction?: 'column' | 'row'
  $status?: InputFeedbackStatus
}

const setSlotStyle = ({ $slot }: InputSlotProps) => {
  if ($slot === 'left') {
    return css`
      flex: unset;
      > *:nth-child(1) {
        margin: 0 0 0 5px;
      }
      > * {
        margin: 0 5px 0 0;
      }
    `
  } else if ($slot === 'right') {
    return css`
      flex: unset;
      button {
        margin: 5px 5px 5px 0 !important;
        font-size: smaller;
        width: max-content;
      }
      > *:nth-child(1) {
        margin: 0 10px 0 0;
      }
      > *,
      > *:last-child {
        margin: 0 5px 0 0;
      }
    `
  }
}
const getInputStatusStyle = ({ $status }: InputStyledProps) => {
  if ($status?.error) {
    return css`
      background-color: ${({ theme }) =>
        theme.input.error.backgroundColor} !important;
      border: 1px solid ${({ theme }) => theme.input.error.borderColor} !important;
      input {
        background-color: transparent;
      }
    `
  }

  if ($status?.success) {
    return css`
      background-color: ${({ theme }) =>
        theme.input.success.backgroundColor} !important;
      border: 1px solid ${({ theme }) => theme.input.success.borderColor} !important;
      input {
        background-color: transparent;
      }
    `
  }

  return css`
    background-color: ${({ theme }) => theme.input.default.backgroundColor};
    border: 1px solid ${({ theme }) => theme.input.default.borderColor};
  `
}
const getInputDirectionStyle = ({ $direction }: InputStyledProps) =>
  $direction === 'column'
    ? css`
        flex-direction: column;
        label {
          margin-bottom: 5px;
        }
      `
    : css`
        flex-direction: row;
        align-items: center;
        label {
          padding-right: 0.5rem;
        }
        p {
          margin-left: 5px !important;
        }
      `

const getWidth = ({ $fluid }: InputFieldAttrProps) =>
  $fluid
    ? css`
        max-width: 100%;
      `
    : css`
        width: 100%;
      `

export const InputStyled = styled.div<InputStyledProps & InputFieldAttrProps>`
  width: 100%;
  font-weight: normal;
  line-height: 19px;
  margin: ${({ $hasMargin }) => ($hasMargin ? '0.5rem 0 1rem 0' : '0')};
  font-size: 1rem !important;
  display: flex;
  border-radius: 0.28571429rem;
  justify-content: flex-start;
  position: relative;
  ${getInputDirectionStyle}

  & input {
    font-size: 0.938rem;
    font-family: ${({ theme }) => theme.font.family};
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    text-align: left;
    line-height: normal;
    padding: 0.555em 10px;
    color: ${({ theme }) => theme.input.default.color};
    border-radius: 0.28571429rem;
    transition:
      box-shadow 0.1s ease,
      border-color 0.1s ease,
      -webkit-box-shadow 0.1s ease;
    box-shadow: none;
    border: 0 !important; /*Semantic override*/
    ${getWidth};
    &:disabled {
      background: ${({ theme }) => theme.input.disabled.background};
      color: ${({ theme }) => theme.input.disabled.color};
    }

    &::placeholder {
      color: ${({ theme }) => theme.input.default.placeholder};
    }
  }

  > div {
    ${getInputStatusStyle}
  }
`
export const InputSlot = styled.div<InputSlotProps>`
  svg {
    opacity: 0.6;
  }
  display: flex;
  align-items: center;

  ${setSlotStyle}
`
export const InputWrapperStyled = styled.div<InputStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 0.28571429rem;

  ${({ $direction }) =>
    $direction === 'row'
      ? css`
          flex: 1;
        `
      : null}
  ${getInputStatusStyle}
`
