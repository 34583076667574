import styled from 'styled-components'

export const RowWrapper = styled.div`
  padding: 1rem 0;
  & + & {
    border-top: 1px solid #b7b7b7;
  }
`

export const RowStyled = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 2fr 1fr 2fr auto;
  align-items: center;
  column-gap: 1rem;
  min-height: 2.5rem;
`

// used for error displaying
export const RowFeedback = styled.div`
  grid-column: 3;
  p {
    font-size: 0.8rem;
  }
`
