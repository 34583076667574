import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`

const FieldGroup = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`

const FieldRow = styled.div`
  display: flex;
  flex-direction: column;

  label {
    padding-left: 0.1rem;
    color: rgb(99, 115, 129);
    font-weight: 600;
  }
`

const SettingsFieldsContainer = styled.div`
  padding: 1rem 0 0;
`

const SaveButtonWrapper = styled.div`
  text-align: right;
  padding-right: 2rem;

  button {
    padding-right: 0;
  }
`

const TitleWrapper = styled.div`
  position: relative;
  text-alignalign: center;
  margin-bottombottom: 1rem;
`

const IconLockWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 5rem;
  transform: translateY(-50%);
`

export const EmailReceiptStyled = {
  Wrapper: Wrapper,
  FieldGroup: FieldGroup,
  SettingsFieldsContainer: SettingsFieldsContainer,
  FieldRow: FieldRow,
  SaveButtonWrapper: SaveButtonWrapper,
  TitleWrapper: TitleWrapper,
  IconLockWrapper: IconLockWrapper,
}
