import styled, { css } from 'styled-components'
import { MdEdit } from 'react-icons/md'

export const AddFilterButtonWrapper = styled.div`
  display: inline-block;
`
interface FilterIconProps {
  hasFilters?: boolean
}

export const FilterIcon = styled.div<FilterIconProps>`
  display: inline;
  font-size: 0.8em;
  margin-right: 0.5em;
  margin-left: 0.2em;
  ${({ hasFilters }) =>
    hasFilters
      ? css`
          color: #191919;
        `
      : css`
          color: #41a6dc;
        `}
`

export const FilterModalWrapper = styled.div`
  position: relative;
  height: 100%;
`

export const FilterModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  // height: 20%;

  h3 {
    font-size: 1.35rem;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
  }
`

export const FilterModalCloseIcon = styled.div`
  font-size: 2rem;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0 10%;
  margin: 0;
  cursor: pointer;
`

export const FilterModalBody = styled.div`
  padding: 1.5rem;
  overflow-y: auto;
  height: 56%;
  margin-bottom: 2rem;
  // padding-bottom: 2rem;
`

export const FilterModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 16%;
  padding: 0 2rem;
  margin: 0;
  // padding: 0 2rem;
  // margin: 2rem;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;

  // margin-top: 4rem;
  // padding-top: 4rem;
`
export const EditIcon = styled(MdEdit)`
  font-size: 1.1em;
  margin-left: 0.2em;
  margin-bottom: 0;
  padding-bottom: 0;
`
export const HasFilterMessage = styled.span`
  color: #191919;
  font-size: 0.93em;
`
export const HasFilterMessageTitle = styled.span`
  font-weight: 600;
  margin-right: 0.4em;
`

export const ButtonStyledLabel = styled.span`
  display: flex;
  align-items: center;
  gap: 1rem;
`
export const StarterLabel = styled.span<{ small?: boolean }>`
  display: inline-block;
  padding: ${({ small }) => (small ? '2px 4px' : '4px 8px')};
  border-radius: 4px;
  background-color: #ff9800;
  color: #fff;
  font-weight: bold;
  font-size: ${({ small }) => (small ? '10px' : '12px')};
  line-height: ${({ small }) =>
    small
      ? '22px'
      : '20px'}; // change this value to adjust the vertical centering
  transition: background-color 0.3s; // add a smooth transition effect
  cursor: pointer; // add cursor pointer to indicate interactivity

  &:hover {
    background-color: #f57c00; // change to a darker color on hover
  }
`

export const FilterRowStyled = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr) 15%;
  grid-gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 1rem;
  margin-bottom: 1rem;
  background-color: #f9fafb;
  label {
    font-size: 13px;
    font-weight: 400;
    color: grey;
  }

  input[disabled] {
    background-color: #f2f3f4;
  }
`

export const FilterNotAvailablePageWrapper = styled.div`
  width: 80%;
  margin: 0 auto;
  padding-top: 2rem;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  & > *:not(:last-child) {
    margin-bottom: 2rem;
  }
`

export const FilterNotAvailablePageText = styled.div`
  font-weight: 600;
`

export const FilterNotAvailablePageImage = styled.img`
  max-width: 100%;
  width: 200px;
`
