import styled, { css } from 'styled-components'
import { ButtonProps } from './index'

const sizeLarge = `1.3rem`

const getSize = ({ $size }: ButtonProps) => {
  switch ($size) {
    // TO BE REMOVE
    case 'inline-small':
      return css`
        padding: 0;
        margin: 0;
        font-size: small;
      `

    case 'large':
      return css`
        font-size: ${sizeLarge};
      `
  }
}

const getSocialProviderBaseStyle = ({ $size }: ButtonProps) => css`
  min-width: 230px;
  margin: 0;
  & svg {
    /* keeping icon left aligned with text centered   */
    position: absolute;
    left: 1rem;
    top: 50%;
    transform: translate(0, -50%);
    height: ${$size === 'large' ? '1.5rem' : '1.3rem'};
    width: ${$size === 'large' ? '1.5rem' : '1.3rem'};
  }
`

const getVariant = ({ $variant, $size }: ButtonProps) => {
  switch ($variant) {
    case 'facebook':
      return css`
        background-color: ${({ theme }) =>
          theme.button.facebook.backgroundColor} !important;
        color: ${({ theme }) => theme.button.facebook.color} !important;
        border: 1px solid
          ${({ theme }) => theme.button.facebook.backgroundColor};
        ${getSocialProviderBaseStyle};
        &:hover {
          background-color: ${({ theme }) =>
            theme.button.facebook.backgroundColorHover} !important;
          transition: all 0.16s ease-in-out;
        }
      `
    case 'tiktok':
      return css`
        background-color: ${({ theme }) =>
          theme.button.tiktok.backgroundColor} !important;
        color: ${({ theme }) => theme.button.tiktok.color} !important;
        border-radius: 0;
        ${getSocialProviderBaseStyle};
        &:hover {
          opacity: 0.7;
        }
      `
    case 'google':
      return css`
        border: 1px solid #dadce0;
        background-color: #fff;
        ${getSocialProviderBaseStyle};
        &:hover {
          background-color: rgba(66, 133, 244, 0.04);
          border-color: #d2e3fc;
        }
      `

    case 'primary':
      return css`
        color: ${({ theme }) => theme.button.primary.color} !important;
        background: ${({ theme }) =>
          theme.button.primary.backgroundColor} !important;
        transition: all 0.16s ease;
        & a {
          color: white;
          text-decoration: none;
        }

        &:hover {
          & a {
            color: white;
            text-decoration: none;
          }
          background: ${({ theme }) =>
            theme.button.primary.backgroundColorHover} !important;
          transition: all 0.16s ease;
        }
      `
    case 'secondary':
      return css`
        background: ${({ theme }) =>
          theme.button.secondary.backgroundColor} !important;
        color: ${({ theme }) => theme.button.secondary.color} !important;
        transition: 0.16s all ease-in;

        &:hover {
          background: ${({ theme }) =>
            theme.button.secondary.backgroundColorHover} !important;
          transition: 0.16s all ease-out;
        }
      `
    case 'link':
      return css`
        box-shadow: none !important;
        background: ${({ theme }) =>
          theme.button.link.backgroundColor} !important;
        padding-left: 0px !important;
        color: ${({ theme }) => theme.button.link.color} !important;
        font-weight: 400 !important;
        margin-bottom: 1rem;
      `
  }
}

export const ButtonStyled = styled.button<ButtonProps>`
  position: relative;
  cursor: pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #e0e1e2 none;
  color: rgba(0, 0, 0, 0.6);
  margin: 0 0.25em 0 0;
  padding: 0.78571429em 1.5em;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  border-radius: 0.28571429rem;
  user-select: none;
  transition:
    opacity 0.1s ease,
    background-color 0.1s ease,
    color 0.1s ease,
    box-shadow 0.1s ease,
    background 0.1s ease;
  -webkit-tap-highlight-color: transparent;

  & svg {
    margin-right: 0.3rem;
  }

  &:hover {
    background-color: #cacbcd;
    background-image: none;
    color: rgba(0, 0, 0, 0.8);
  }

  ${({ $fluid }) =>
    $fluid
      ? css`
          width: 100%;
        `
      : null}

  ${({ disabled }) =>
    disabled &&
    css`
      cursor: default;
      opacity: 0.45 !important;
      background-image: none !important;
      box-shadow: none !important;
      pointer-events: none !important;
    `}

    ${({ $loading }) =>
    $loading &&
    css`
      & {
        color: transparent !important;
      }
      i {
        visibility: hidden;
      }
      &::before {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        margin: -0.64285714em 0 0 -0.64285714em;
        width: 1.28571429em;
        height: 1.28571429em;
        border-radius: 500rem;
        border: 0.2em solid rgba(0, 0, 0, 0.15);
      }
      &::after {
        position: absolute;
        content: '';
        top: 50%;
        left: 50%;
        margin: -0.64285714em 0 0 -0.64285714em;
        width: 1.28571429em;
        height: 1.28571429em;
        animation: button-loader-spin 0.6s linear;
        animation-iteration-count: infinite;
        border-radius: 500rem;
        border-color: #fff transparent transparent;
        border-style: solid;
        border-width: 0.2em;
        box-shadow: 0 0 0 1px transparent;
      }
    `}

    @keyframes button-loader-spin {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }

  ${getVariant}
  ${getSize}
`
