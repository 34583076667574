import styled from 'styled-components'

export const FormulaModalWrapper = styled.div`
  position: relative;
  height: 100%;
  font-size: 0.9rem;
`

export const FormulaModalHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0;
  margin: 0;
  // height: 20%;

  h3 {
    font-size: 1.25rem;
    padding-left: 1.5rem;
    padding-top: 1.5rem;
  }
`
export const FormulaModalHero = styled.div`
  padding: 0.6rem 1.5rem 0.6rem 1.5rem;
  margin-bottom: 0;
  h5 {
    font-weight: 600;
    margin-bottom: 0.5rem;
    margin-top: 0;
  }
  small {
    color: #525e6d;
    font-size: 0.85rem;
  }
`

export const FormulaModalCloseIcon = styled.div`
  font-size: 2rem;
  background-color: #f8f8f8;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  border-radius: 0 10%;
  margin: 0;
  cursor: pointer;
`

export const FormulaModalBody = styled.div`
  padding: 0 1.5rem;
  overflow-y: auto;
  height: 56%;
  margin-bottom: 2rem;
  h5 {
    font-size: 0.85rem;
    padding: 0 0 0 0;
    margin: 1rem 0 0 0;
  }
  small {
    color: #525e6d;
    font-style: italic;
    font-size: 0.78rem;
  }
`

export const FormulaModalFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 16%;
  padding: 0 1.5rem 0 0;
  margin: 0;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  button {
    width: 8.5rem;
  }
  gap: 1rem;
`
